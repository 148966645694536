body{background-color:#999; 
  background-image:url(/gallery/IGL_bkg.png); 
  background-position:top; 
  background-repeat:no-repeat; 
  font-family:Tahoma,Geneva,sans-serif;   
  margin:0; 
  padding:0;
  width:100%;
  height:100%;
}
.App {
  text-align: center;
}
#basic-navbar-nav{
  padding-right:10px;
}
.fa {
  padding: 8px 8px 0px 8px;
  font-size: 22px;
  width: 33px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}
.fa:hover {
  opacity: 0.7;
}
.fa-facebook {
  background: #3B5998;
  color: white;
}
.navbar{
  width:101%;
}
#IGL_footer{
  margin-left:0px;
  margin-right:0px;
}
#mainContent{
  padding-top:76px;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#MainContent a:link, #MainContent  a:visited{
  color: #FF9;
    font-weight: normal;
    text-decoration: none;
}

.App-link {
  color: #61dafb;
}
.contContainer{
  max-width: 950px;
}


.frame1, .frame2{
  position: relative; top: 0; left: 0; width: 100%; height: 600px;display:block;
}

#banner{
  background-image:url(/gallery/IGL_header_bg_wrapper_outter.jpg);
  background-position:bottom;
  background-repeat:repeat-x;
  height:535px;
}
#bannerContainer{
  background-image:url(/gallery/IGL_header_bg_wrapper.jpg);
  background-position:center top;
  background-repeat: no-repeat;
  height:535px;
  margin: 0 auto;
  width:100%;

}
img{
  border-style:none;
  color:#fff;
}
nav{ background-color:#312c2d; }

#caruselContainer{
  float: right;
  margin-right:25%;
  margin-top: 40px;
  width: 500px;
}
.contactHead{
  position:relative;
  top:15px;
}
#customHR{
  height:30px;
  background-color:black;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#duckImg{
  float:right;
}
#fishImg{
  float:left;
  position:relative;
  right:200px;
  top:-300px;
  margin-bottom:-300px;
}
h2{
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  font-family: Impact;
  color: #505050;
  margin: 0 0 10px;
  padding: 10px 0 10px;
  letter-spacing: -1px;
}
h3{
	color: #800000;
	font-size: 20px;
	font-weight: bold;
	margin: 0 0 10px;
	padding: 10px 0 20px;
	font-style: italic;
}
h4{
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0;
  font-style: italic;
}
summary{
  font-size:12px; 
  font-weight:bold;
}
#book{
  position:relative;
  top:2em;
}
.em_text span{
  color:#333; 
  font-style:normal; 
  font-weight:lighter
}

.map-responsive{
  overflow:hidden;
  padding-bottom:50%;
  position:relative;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

.cS-hidden {
  height: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
}
#imgSlider{
  margin-top:30px;
}

.item-a{
  width:200px;
}
#IGL_footer a{color:#B31B03}
#MainContent ul li{
	list-style-type:none;
	font-family:Tahoma, Geneva, sans-serif;
	font-size:11px;
	list-style-image:url(/gallery/bullet.gif);
	list-style-position:inside;
}
table tr td p{
  margin:0;
  padding:0;
  font-size:12px;
}
.parallax {
  /* The image used */
  /* Set a specific height */
  
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#para1 {
  height:35em;
  background-image:url(/gallery/DSC_1294.JPG);
  width:100%;
 
}
#para2 {
  height:35em;
  background-image:url(/gallery/DSC_1286.JPG);
  width:100%;
}
#para3 {
  height:35em;
  background-image:url(/gallery/DSC_1281.JPG);
  width:100%;
}
#para4 {
  height:35em;
  background-image:url(/gallery/DSC_1358.JPG);
  width:100%;
  
}
#References .row .col p{
  margin:0;
  padding:0;
  font-size:12px;
}

@media(max-width:1800px){
  #caruselContainer{
    
    width: 400px;
  }
}
@media(max-width:1380px){
  #caruselContainer{
    width: 300px;
    margin-right:20%;
    margin-top: 80px;
  }
}
@media(max-width:1000px){
  #caruselContainer{
    margin-top: 80px;
    margin-right:5%;
    
  }
}
@media(max-width:992px){
  iframe{
    height:500px;
  }
}
@media(max-width:800px){
  #bannerContainer{
    background-image:none;
  }
  #caruselContainer{
    width:500px;
    margin-right:15%;
    margin-top: 10px;
  }
  
    #banner{
      height:400px;
    }
}
.Horzbar{
  color:white;
}
@media(max-width:768px){
  .item-a{
    width:100px;
  }
   #duckImg, #fishImg{
    display: none;
  }

  .frame1{
    height:400px;
  }
  .frame2{
    height:400px !;
  }
  
}
@media(max-width:700px){
  #caruselContainer{
    margin-right:20%;
    margin-top: 10px;
    width: 400px;
  }
  
}
@media(max-width:600px){
  #caruselContainer{
    margin-right:10%;
    margin-top: 10px;
    width: 400px;
  }
  .center-block{
    padding-left:20px;
    padding-right:20px;
  }
  
}
@media(max-width:500px){
  #caruselContainer{
    width:100%;
    margin-left:auto;
    margin-right:auto;
  }
  
}

@media(max-width:411px){
  .frame1{
    height:200px;
  }
  
}
@media(max-width:320px){
  table{
    display:none;
  }
  #mapDiv{
    display: none;
  }
  #root{
    padding-right:1px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
